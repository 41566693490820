export default function transfrom(run) {
  var mappingRules = []

  var legacyData = run.lightsv2.flatMap(
    (light, lightIndex) => {
      const led = parseInt(light.led.split(/\s+/).pop(), 10)
      mappingRules.push(light)
      return run.data[lightIndex].flatMap(
        (lightData, cycleIndex) => lightData.flatMap(
          (sideData, sideIndex) => sideData.map(
            ([data_id, timestamp, zone_fluo, picture]) => ({
              data_id,
              timestamp,
              led,
              abs_timestamp: convertToISOString((new Date(run.start)).getTime() + timestamp * 1e3),
              side: run.sides[sideIndex],
              cycle: run.cycles[cycleIndex],
              analysed: Boolean(picture),
              picture: picture !== null,
              fluo: zone_fluo.reduce(
                (fluos, fluoValue, fluoIndex) => Object.assign(fluos, { [run.zones[fluoIndex]]: fluoValue }), {}
              )
            })
          )
        )
      )
    }
  )

  legacyData.sort((a, b) => {
    if (a.run_id > b.run_id) {
      return 1
    }

    if (a.run_id < b.run_id) {
      return -1
    }

    return a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
  })

  return {
    mappingRules,
    data: legacyData
  }
}

function convertToISOString(timestamp) {
  return (new Date(timestamp)).toISOString()
}
