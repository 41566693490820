<template>
    <div>
        <h4 class="component-title">
            Polonix results display
            <small>
                <help-view>You can visualize here the polonix results.</help-view></small>
        </h4>
        <b-row class="pt-4 pb-4">
            <b-col cols="12">
                <b-button-group class="full-width">
                    <b-button @click="downloadJSON()" title="Export results in JSON"><b-icon icon="download"
                            class="mr-2"></b-icon>Download JSON</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row v-if="results.patchwork && results.scatter">
            <b-col md="6">
                <b-img :src="'data:image/png;base64, ' + results.patchwork" fluid alt="Patchwork results"></b-img>
            </b-col>
            <b-col md="6">
                <b-img :src="'data:image/png;base64, ' + results.scatter" fluid alt="Scatter plot results"></b-img>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import HelpView from "../HelpView.vue";

export default {
    name: "Polonix",
    props: ["analysis_data", "run_infos"],
    computed: {
        results: function () {
            return this.analysis_data
        },
        infos: function () {
            return this.run_infos
        }
    },
    methods: {
        downloadJSON() {
            const jsonString = JSON.stringify(this.results, null, 2);
            const blob = new Blob([jsonString], { type: "application/json" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            const now = new Date();
            const fileName =
                "polonix_results_" +
                this.infos.machine_name +
                "_" +
                this.infos.ananke_id +
                "_" +
                now.getFullYear() +
                (now.getMonth() + 1) +
                now.getDate() +
                "T" +
                now.getHours() +
                now.getMinutes() +
                now.getSeconds() +
                ".json";
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    components: {
        HelpView,
    },
}
</script>

<style scoped>
.full-width {
    width: 100%
}
</style>