<template>
  <div class="data-plots">
    <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success" dismissible
      @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Advanced data viz copied to
      clipboard
    </b-alert>
    <b-container class="main" fluid>
      <header class="mb-3">
        <h3>
          Data plots
          <small><help-view>See here the advanced data plots.</help-view></small>
        </h3>

        <transition name="fade">
          <data-exporter v-if="hasData && hasOneRun" :data="firstSelectedRunLog" />
        </transition>
        <transition name="fade">
          <data-exporter v-if="hasData && hasMultipleRuns && actualPlotView === ANALYSIS_VIEW
          " :data="multi_data" />
        </transition>

        <transition name="fade">
          <small v-if="headerHint" class="header-hint">{{ headerHint }}</small>
        </transition>

        <transition name="fade">
          <b-form-select v-if="hasRuns" :options="plotViewOptions" :value="actualPlotView" @change="setPlotView"
            :disabled="hasOneRun || isRunSelectionLimitExeeded" class="plot-view-selector" size="sm"></b-form-select>
        </transition>
      </header>

      <div class="plot-wrapper" :data-no-data="placeholderText" :class="!hasData && 'placeholder'">
        <log-plot v-if="isLogViewActive && hasSelectedRunLogs" ref="logPlot" :run_logs="selectedRunLogs"
          :metaDataKeys="metaDataKeys" :log_types="log_types" :selectedLogType="log_type"
          @log-type-change="changeLogType" @replot-needed="replotLog" @resize-needed="resizeLog"
          @restyle-needed="restyleLog" v-on="forwardedPlotEvents"></log-plot>

        <analysis-plot v-else-if="hasMultiData" :multi_data="multi_data" v-on="forwardedPlotEvents"></analysis-plot>

        <transition name="fade">
          <div class="propgress-overlay" v-if="isBusy">
            <b-progress v-if="isFetchingRunLogs" class="logs-fetch-progress" :value="fetchedLogsCount"
              :max="unfetchedDisplayRunsCount" animated></b-progress>
            <b-spinner v-else></b-spinner>
          </div>
        </transition>
      </div>
    </b-container>
  </div>
</template>

<style scoped>
@import url("../../style/animation.css");

.data-plots,
.main {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
}

/* :nth-child(1n) increases the specity without having to use last resort !important */
header:nth-child(1n) {
  display: flex;
  align-items: center;
  flex: none;
}

header>*+* {
  margin-left: 0.75em;
}

.header-hint {
  margin-left: auto;
}

.header-hint::first-letter {
  text-transform: uppercase;
}

:not(.header-hint)~.plot-view-selector {
  width: unset;
  margin-left: auto;
}

.header-hint~.plot-view-selector {
  margin-left: 1em;
}

.plot-wrapper {
  flex: 1 1 auto;
  position: relative;
}

.placeholder {
  background-color: rgb(240, 240, 240);
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder::before {
  content: attr(data-no-data);
}

.propgress-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(240, 240, 240, 75%);
  border-radius: 0.5em;
  backdrop-filter: blur(3px);
}

.logs-fetch-progress {
  width: 25%;
  height: 0.5em;
  background-color: white;
}
</style>

<script>
import axios from "axios";
import HelpView from "../HelpView.vue";
import DataExporter from "./DataExporter.vue";
import LogPlot from "./LogPlot.vue";
import AnalysisPlot from "./AnalysisPlot.vue";
import { runOneBatchAtATime } from "../../utils/async";
import { omit } from "../../utils/object";
import { LOG_VIEW, ANALYSIS_VIEW } from "../../constants/plot";

const { CancelToken } = axios;

const DEBUG_LOG_TYPE = "debug";
const DEFAULT_LOG_TYPE = DEBUG_LOG_TYPE;

function delay() {
  const RESPONSIVNESS_PLOT_DELAY = 200;

  return new Promise((resolve) =>
    setTimeout(resolve, RESPONSIVNESS_PLOT_DELAY)
  );
}

export default {
  name: "DataPlots",

  components: {
    HelpView,
    DataExporter,
    LogPlot,
    AnalysisPlot,
  },

  props: {
    displayRuns: {
      type: Array,
      default: () => []
    },
    selectedVersion: String
  },

  data() {
    return {
      isFetchingRunLogs: false,
      isPlottingLog: false,
      log_types: [],
      log_type: "",
      dismiss_countdown: 0,
      run_logs_cache: {},
      multi_data: [],
      selectedPlotView: undefined,
      fetchedLogsCount: 0,
    };
  },

  watch: {
    async displayRuns() {
      if (this.hasRuns) {
        if (this.isLogViewActive) {
          await this.getRunLogTypes();
          this.updateLogViewData();
        } else {
          this.formatMultiData();
        }
      } else {
        this.multi_data = [];
      }
    },

    async selectedPlotView() {
      this.cancelRunLogsFetch?.();
      if (this.actualPlotView === LOG_VIEW) {
        if (this.log_types.length === 0) {
          await this.getRunLogTypes();
        }
        this.updateLogViewData();
      } else {
        this.formatMultiData();
      }
    },

    async selectedVersion() {
      if (this.hasRuns && this.actualPlotView == ANALYSIS_VIEW) {
        this.formatMultiData();
      }
    },

    actualPlotView: {
      handler(actualPlotView) {
        this.$emit("plot-view-select", {
          actualPlotView,
          selectedPlotView: this.selectedPlotView,
        });
      },

      immediate: true,
    },
  },

  methods: {
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },

    replotLog() {
      this.getRunLogs();
    },

    async resizeLog() {
      this.showLogPlotOverlay();
      await delay();
      await this.$refs.logPlot.resizePlot();
      this.hideLogPlotOverlay();
    },

    async restyleLog() {
      this.showLogPlotOverlay();
      await delay();
      await this.$refs.logPlot.restylePlot();
      this.hideLogPlotOverlay();
    },

    updateLogViewData() {
      this.getRunLogs();
      this.multi_data = [];
    },

    changeLogType({ log_type, run_ids }) {
      this.log_type = log_type;
      this.run_logs_cache = omit(this.run_logs_cache, run_ids);
      this.getRunLogs();
    },

    async getRunLog(
      {
        run_id,
        data: {
          info: { ananke_id, machine_name },
        },
      },
      log_source
    ) {
      const MACHINE_NAME_SEQUENCE = 1;
      const machineNameSequence =
        machine_name?.split(" ")[MACHINE_NAME_SEQUENCE];

      const response = await axios.get("/api/v1/run/logs", {
        params: { run_id: run_id, log_type: this.log_type },
        cancelToken: log_source.token,
      });
      const config = response.config
      const data = response.data.data
      return {
        run_id,
        legend: machineNameSequence + "-" + ananke_id,
        data: data.map((ele) => {
          const new_content = {};
          let content = ele;

          if (config.params.log_type.toLowerCase() === DEBUG_LOG_TYPE) {
            content = ele.content;
          } else {
            delete content.type;
          }

          for (let colname in content) {
            let colvalue = content[colname];
            if (Array.isArray(colvalue)) {
              for (let index in colvalue) {
                let suffix = parseInt(index) + 1;
                new_content[colname + "_" + suffix.toString()] =
                  colvalue[index];
              }
            } else {
              new_content[colname] = colvalue;
            }
          }

          new_content.index = this.displayRunIdsIndexesMapping[run_id];

          return new_content;
        })

      };
    },

    async getRunLogs() {
      let cancelled = false;
      let log_source = CancelToken.source();

      this.cancelRunLogsFetch = () => {
        delete this.cancelRunLogsFetch;
        log_source.cancel();
        cancelled = true;
        this.isFetchingRunLogs = false;
        this.hideLogPlotOverlay();
        this.$emit("free");
      };

      this.$emit("busy");
      this.showLogPlotOverlay();
      this.isFetchingRunLogs = true;
      this.fetchedLogsCount = 0;
      const run_logs = [];

      const fetches = this.unfetchedDisplayRuns.map(
        (displayRun) => async () => {
          const result = await this.getRunLog(displayRun, log_source);

          if (!cancelled) {
            this.fetchedLogsCount++;
          }

          return {
            ...result,
            metaData: displayRun.data.meta.reduce(
              (metaData, { key, value }) =>
                Object.assign(metaData, { [key]: value }),
              {}
            ),
          };
        }
      );

      const batches = runOneBatchAtATime({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
        failSilently: true,
        onSilentError(error) {
          if (axios.isCancel(error)) {
            console.warn("Request cancelled for run logs", error);
          } else {
            console.error(error);
          }
        },
      });

      for await (const batch_run_logs of batches) {
        run_logs.push(...batch_run_logs);
        batches.next(cancelled);
      }

      if (!cancelled) {
        this.isFetchingRunLogs = false;

        if (run_logs.length > 0) {
          this.run_logs_cache = run_logs.reduce(
            (run_logs_cache, run_log) => {
              run_logs_cache[run_log.run_id] = run_log;
              return run_logs_cache;
            },
            { ...this.run_logs_cache }
          );
        }

        if (this.selectedRunLogs.length > 0) {
          await this.$nextTick();
          await delay();
          await this.$refs.logPlot.plot();
        }

        this.hideLogPlotOverlay();
        this.$emit("free");
      }
    },

    async getRunLogTypes() {
      const { run_id } = this.displayRuns[0];
      this.isFetchingRunLogs = true;
      this.$emit("busy");
      this.log_types = [];
      this.log_types = (await axios
        .get("/api/v1/run/log-types", {
          params: { run_id: run_id },
        })).data.types.sort()
      const normalizedLogType = this.log_type?.toLowerCase();
      let autoSelectedIndex = this.log_types.findIndex(
        (type) => type.toLowerCase() === normalizedLogType
      );

      if (autoSelectedIndex < 0) {
        autoSelectedIndex = this.log_types.findIndex(
          (type) => type.toLowerCase() === DEFAULT_LOG_TYPE
        );
      }
      this.log_type =
        this.log_types[autoSelectedIndex > -1 ? autoSelectedIndex : 0];
    },

    formatMultiData() {
      const vm = this;
      const formattedMultiData = [];
      vm.displayRuns.forEach((run_data) => {
        if (run_data.data.analysis[this.selectedVersion]?.data && !run_data.data.analysis[this.selectedVersion].polonix)
          Object.keys(run_data.data.analysis[this.selectedVersion].data).forEach((fluor) => {
            const fluor_data = run_data.data.analysis[this.selectedVersion].data[fluor];
            Object.keys(fluor_data).filter(key => !['color', 'description'].includes(key)).forEach((chamber) => {
              const chamber_data = fluor_data[chamber];
              const new_data = {
                index: this.displayRunIdsIndexesMapping[run_data.run_id],
                run_id: run_data.run_id,
                fluor: fluor,
                chamber: chamber,
              };
              Object.keys(run_data.data.info).forEach((key) => {
                new_data[key] = run_data.data.info[key];
              });
              run_data.data.meta.forEach((meta) => {
                new_data[meta.key] = meta.value;
              });
              Object.keys(chamber_data).forEach((key) => {
                if (!Array.isArray(chamber_data[key])) {
                  new_data[key] = chamber_data[key];
                }
              });
              formattedMultiData.push(new_data);
            });
          });
      });
      vm.multi_data = formattedMultiData;
    },

    setPlotView(selectedPlotView) {
      this.selectedPlotView = selectedPlotView;
    },

    showLogPlotOverlay() {
      this.isPlottingLog = true;
      this.$emit("busy");
    },

    hideLogPlotOverlay() {
      this.isPlottingLog = false;
      this.$emit("free");
    },

    clearCache() {
      this.run_logs_cache = {};
    },
  },

  computed: {
    hasOneRun() {
      return this.displayRuns.length === 1;
    },

    hasMultipleRuns() {
      return this.displayRuns.length > 1;
    },

    hasRuns() {
      return this.displayRuns.length > 0;
    },

    hasLogTypes() {
      return this.log_types.length > 0;
    },

    hasMultiData() {
      return this.multi_data.length > 0;
    },

    multiDataProps() {
      return Object.keys(this.multi_data[0]);
    },

    hasSelectedRunLogs() {
      return this.selectedRunLogs.length > 0;
    },

    firstSelectedRunLog() {
      return this.hasSelectedRunLogs ? this.selectedRunLogs[0].data : [];
    },

    actualPlotView() {
      return (!this.selectedPlotView ||
        this.selectedPlotView === ANALYSIS_VIEW) &&
        this.hasMultipleRuns
        ? ANALYSIS_VIEW
        : LOG_VIEW;
    },

    isLogViewActive() {
      return this.actualPlotView === LOG_VIEW;
    },

    isRunSelectionLimitExeeded() {
      return (
        this.actualPlotView === ANALYSIS_VIEW &&
        this.displayRuns.length >
        this.$root.$options.config.LOG_PLOT_TRACES_LIMIT
      );
    },

    hasData() {
      return (
        (this.isLogViewActive && this.hasSelectedRunLogs) ||
        (!this.isLogViewActive && this.hasMultiData)
      );
    },

    unfetchedDisplayRuns() {
      return this.displayRuns?.filter(
        (displayRun) => !this.run_logs_cache[displayRun.run_id]
      );
    },

    unfetchedDisplayRunsCount() {
      return this.unfetchedDisplayRuns?.length || 0;
    },

    selectedRunLogs() {
      return (
        this.displayRuns?.reduce((selectedRunLogs, { run_id }) => {
          const run_log = this.run_logs_cache[run_id];

          if (run_log) {
            selectedRunLogs.push(run_log);
          }

          return selectedRunLogs;
        }, []) || []
      );
    },

    metaDataKeys() {
      return Array.from(
        new Set(
          this.displayRuns.flatMap(({ data: { meta } }) =>
            meta.map(({ key }) => key)
          )
        )
      );
    },

    headerHint() {
      if (this.isRunSelectionLimitExeeded) {
        return `log view cannot handle more than ${this.$root.$options.config.LOG_PLOT_TRACES_LIMIT} traces`;
      }

      if (this.hasOneRun) {
        return `analysis view is only available for multiple runs`;
      }

      return undefined;
    },

    isBusy() {
      return this.isPlottingLog || this.isFetchingRunLogs;
    },

    displayRunIdsIndexesMapping() {
      return [...this.displayRuns]
        .sort(
          (
            {
              data: {
                info: { start: startA },
              },
            },
            {
              data: {
                info: { start: startB },
              },
            }
          ) => (startA > startB ? 1 : startA < startB ? -1 : 0)
        )
        .reduce(
          (map, { run_id }, index) =>
            Object.assign(map, { [run_id]: index + 1 }),
          {}
        );
    },

    placeholderText() {
      return !this.hasRuns
        ? "No run selected"
        : !this.hasData && "No data available";
    },

    forwardedPlotEvents() {
      const SELECT_GRAPH_EVENT = "select-graph";
      const selectGraphEventListener = this.$listeners[SELECT_GRAPH_EVENT];

      return selectGraphEventListener
        ? { [SELECT_GRAPH_EVENT]: selectGraphEventListener }
        : {};
    },
  },

  created() {
    this.plotViewOptions = [
      { text: "Log view", value: LOG_VIEW },
      { text: "Analysis view", value: ANALYSIS_VIEW },
    ];
    this.ANALYSIS_VIEW = ANALYSIS_VIEW;
  },
};
</script>
