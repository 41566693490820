<template>
  <div>
    <h4>
      Display options
      <small>
        <b-iconstack v-if="all_leds.length > 0" class="clickable mr-2" @click="resetOptions()" title="Reset options">
          <b-icon stacked icon="square-fill" variant="danger"></b-icon>
          <b-icon stacked icon="arrow-repeat" scale="0.75" variant="white"></b-icon>
        </b-iconstack>
        <help-view>Enter here your data visualization options.</help-view></small>
    </h4>
    <div v-if="all_leds.length > 0 && !is_polonix">
      <b-form-group label="Display curve:" label-for="select-display" label-cols-xl="4">
        <b-form-select id="select-display" size="sm" v-model="options.display_curve" :options="(fetching && !error)
          ? all_curves.filter((ele) => ele.value == 'raw')
          : all_curves
          "></b-form-select>
      </b-form-group>
      <b-form-group label="Display targets:" label-cols-xl="4">
        <b-form-checkbox v-model="display_all_targets" id="select-display_all_targets" stacked switch
          @change="displayAllTargets()"><span v-if="display_all_targets">Hide all</span><span v-else>Display
            all</span></b-form-checkbox>
        <hr>
        <b-form-checkbox-group v-model="options['display_' + option_data]" :options="options['all_' + option_data]"
          stacked switches></b-form-checkbox-group>
      </b-form-group>
      <b-form-group label="Display chambers:" label-cols-xl="4">
        <b-form-checkbox-group v-model="options.display_chambers" :options="all_chambers" stacked
          switches></b-form-checkbox-group>
      </b-form-group>
      <b-form-checkbox v-model="options.start_from_one" :disabled="options.display_curve != 'raw'" switch>Synchronize on
        cycle 1
      </b-form-checkbox>
      <b-form-checkbox v-model="options.no_scale_y" :disabled="options.display_curve != 'raw'" switch>No scale on Y axis
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import HelpView from "../HelpView.vue";
import { translate } from "../../utils/translate"

export default {
  name: "VizOptions",
  props: [
    "data_options",
    "viz_options",
    "fetching",
    "error",
    "color_scale",
    "is_polonix"
  ],
  computed: {
    all_leds: function () {
      const all_leds = this.data_options.fluor_mapping.map((led_option) => {
        const html =
          this.dashArrayToImage(this.styleToDashArray(led_option.style), this.color_scale(led_option.name)) +
          "LED " +
          led_option.led +
          " - " +
          led_option.name +
          " - " +
          this.getTranslated(led_option.gene);
        return {
          value: led_option.led,
          html: html,
          name: led_option.name,
          gene: led_option.gene,
          led: led_option.led,
          filter: led_option.filter,
          color: led_option.color,
          style: led_option.style,
        };
      });
      all_leds.sort((a, b) => a.value - b.value);
      return all_leds;
    },
    all_targets: function () {
      const all_targets = this.data_options.fluor_mapping.flatMap(
        ele => ele.hcpcr_fluos).map((target_option) => {
          const html =
            this.dashArrayToImage(this.styleToDashArray(target_option.style), this.color_scale(target_option.name)) +
            "LED " + this.data_options.fluor_mapping.find((ele) =>
              ele.hcpcr_fluos.map(ele2 => ele2.name).includes(target_option.name)
            ).led +
            " - " +
            target_option.name +
            " - " +
            this.getTranslated(target_option.gene);
          return {
            value: target_option.name,
            html: html,
            name: target_option.name,
            gene: target_option.gene,
            color: target_option.color,
            style: target_option.style,
          };
        });
      all_targets.sort((a, b) => a.value - b.value);
      return all_targets;
    },
    all_chambers: function () {
      return this.data_options.chambers;
    },
    option_data: function () {
      return this.options.display_curve == "raw" ? "leds" : "targets"
    }
  },
  data: function () {
    return {
      all_curves: [
        { value: "raw", text: "Raw data" },
        { value: "processed", text: "Processed data" },
        { value: "sigmoid", text: "Sigmoid fitting" },
        { value: "threshold", text: "Threshold detection" },
      ],
      options: {
        display_curve: "raw",
        display_leds: [],
        display_targets: [],
        display_chambers: [],
        start_from_one: true,
        no_scale_y: false,
      },
      display_all_targets: false
    };
  },
  watch: {
    all_leds: function () {
      this.options.all_leds = this.all_leds
      const led_options = this.all_leds.map((ele) => ele.value)
      this.options.display_leds = this.options.display_leds.filter(
        ele => led_options.includes(ele))
      if (this.options.display_leds.length == 0) {
        this.options.display_leds = led_options;
      }
    },
    all_targets: function () {
      this.options.all_targets = this.all_targets
      const target_options = this.all_targets.map((ele) => ele.value)
      this.options.display_targets = this.options.display_targets.filter(
        ele => target_options.includes(ele))
      if (this.options.display_targets.length == 0) {
        this.options.display_targets = target_options;
      }
    },
    all_chambers: function () {
      this.options.all_chambers = this.all_chambers
      if (this.options.display_chambers.length == 0) {
        this.options.display_chambers = this.all_chambers;
      }
    },
    fetching: function () {
      if (this.fetching && !this.error) {
        this.options.display_curve = "raw";
      }
    },
    options: {
      deep: true,
      handler: function () {
        this.display_all_targets = this.options[
          "display_" + this.option_data].length == this.options[
            "all_" + this.option_data].length;
        this.$emit("update:viz_options", this.options);
      },
    },
  },
  methods: {
    getTranslated(key) {
      return translate(key)
    },
    styleToDashArray(style) {
      switch (style) {
        case 'solid':
          return []
        case 'dashed':
          return ['20%25', '20%25']
        case 'dotted':
          return ['8%25', '20%25']
        case 'longdash':
          return ['40%25', '10%25']
        case 'twodash':
          return ['20%25', '12%25', '20%25', '40%25']
        case 'dotdash':
          return ['20%25', '12%25', '8%25', '12%25']
      }
    },
    dashArrayToImage(dashArray, color) {
      return (
        "<div class=\"color_legend\" style=\"background-image: " +
        "url('data:image/svg+xml,%3csvg width=\\'100%25\\' height=\\'100%25\\' " +
        "xmlns=\\'http://www.w3.org/2000/svg\\' %3e%3crect width=\\'80%25\\' " +
        "x=\\'10%25\\' height=\\'80%25\\' y=\\'10%25\\' fill=\\'" +
        color +
        "\\' fill-opacity=\\'40%25\\' stroke-dasharray=\\'" +
        dashArray.join('%2c ') +
        "\\' rx=\\'10%25\\' ry=\\'10%25\\' stroke=\\'" +
        color +
        "\\' stroke-width=\\'15%25\\' stroke-linecap=\\'butt\\'/%3e %3c/svg%3e');\"'></div>"
      )
    },
    displayAllTargets() {
      this.options["display_" + this.option_data] = this.display_all_targets ? this.options[
        "all_" + this.option_data].map(ele => ele.value) : []
    },
    resetOptions() {
      this.options.display_curve = "raw";
      this.options.display_leds = this.all_leds.map((ele) => ele.value);
      this.options.display_targets = this.all_targets.map((ele) => ele.value);
      this.options.display_chambers = this.all_chambers;
      this.options.start_from_one = true;
      this.options.no_scale_y = false;
    },
  },
  components: {
    HelpView,
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

div>>>.col-form-label {
  padding-top: 0;
}

div>>>.color_legend {
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  margin-right: 0.5rem;
}
</style>